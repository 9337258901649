<template>
  <div v-if="dialog" class="relative z-50">
    <div class="fixed inset-0 bg-black bg-opacity-80"></div>
    <div class="fixed inset-0 overflow-auto">
      <div class="flex items-center justify-center h-full">
        <div
          class="bg-white rounded-lg p-4 w-full max-w-sm md:max-w-md lg:max-w-md md:mx-0 mx-2"
        >
          <div>
            <div class="text-lg font-bold">Select Country</div>
            <div class="font-bold mt-2">
              <div class="flex flex-col gap-2">
                <div
                  class="flex flex-row gap-4 rounded-md p-1 cursor-pointer hover:bg-gray-400"
                  @click="confirm('ID')"
                >
                  <img
                    src="@/assets/icons/flags/indonesia.svg"
                    class="w-16 md:w-28 md:h-16"
                    alt="IDR"
                  />
                  <div class="flex flex-row justify-center">
                    <div class="grid items-center">Indonesian Rupiah (IDR)</div>
                    <div
                      class="w-16 h-full grid grid-cols-1 content-center justify-items-end"
                    >
                      <div
                        class="rounded-full h-7 w-7 border-4 border-yellow p-2 grid grid-cols-1 content-center justify-items-center"
                      >
                        <div
                          class="rounded-full h-4 w-4"
                          :class="{
                            'bg-primary': $store.state.countryCode == 'ID',
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="flex flex-row gap-4 rounded-md p-1 cursor-pointer hover:bg-gray-400"
                  @click="confirm('MY')"
                >
                  <img
                    src="@/assets/icons/flags/malaysia.svg"
                    class="w-16 md:w-28 md:h-16"
                    alt="MYR"
                  />
                  <div class="flex flex-row justify-center">
                    <div class="grid items-center">Malaysian Ringgit (MYR)</div>
                    <div
                      class="w-16 h-full grid grid-cols-1 content-center justify-items-end"
                    >
                      <div
                        class="rounded-full h-7 w-7 border-4 border-yellow p-2 grid grid-cols-1 content-center justify-items-center"
                      >
                        <div
                          class="rounded-full h-4 w-4"
                          :class="{
                            'bg-primary': $store.state.countryCode == 'MY',
                          }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      mapCurrency: {
        ID: "IDR",
        MY: "MYR",
      },
    };
  },

  methods: {
    open() {
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async confirm(cur) {
      var curCode = this.mapCurrency[cur];
      try {
        this.$store.commit("setOverlayLoading", true);
        const res = await this.$http.post(
          `${this.$apiTripweWeb}/config/currency`,
          {
            kode: curCode,
          }
        );
        if (res.data.status == "200") {
          this.$store.commit("setCurrency", res.data.response);
        }
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
        this.$store.commit("setCountryCode", cur);
        this.resolvePromise(true);
        this.dialog = false;
      }
    },
    cancel() {
      this.resolvePromise(false);
      this.dialog = false;
    },
  },
};
</script>
