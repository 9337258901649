<template>
  <header class="shadow-lg whitespace-nowrap">
    <div class="bg-f5 w-full py-1 hidden md:block">
      <div
        class="mx-auto max-w-screen-lg lg:max-w-screen-2xl xl:max-w-screen-xl flex justify-between items-center text-gray-500 text-xs md:text-sm lg:text-sm px-4 lg:px-0">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://tripwe.com/download/"
          class="flex items-center hover:text-secondary">
          <font-awesome-icon icon="fa-solid fa-mobile-screen-button" size="lg" />
          <div class="ml-2">Download Tripwe App</div>
        </a>
        <div class="flex space-x-2 md:space-x-4">
          <button class="flex items-center hover:text-secondary" type="button" @click="openSelectRegion" v-show="false">
            <span class="hover:text-secondary cursor-pointer">
              <img :src="require(`@/assets/icons/flags/${currentCountryCode}`)" width="20px" />
            </span>
            &nbsp; ({{ $store.state.countryCode }})
          </button>
          <a
            target="_blank"
            rel="noopener noreferrer"
            @click="$router.push({ name: 'FAQ' })"
            class="flex items-center hover:text-secondary">
            <span class="hover:text-secondary cursor-pointer">FAQ</span>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            @click="$router.push({ name: 'OurPartner' })"
            class="flex items-center hover:text-secondary">
            <span class="hover:text-secondary cursor-pointer">Our Partner</span>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            @click="$router.push({ name: 'About' })"
            class="flex items-center hover:text-secondary">
            <span class="hover:text-secondary cursor-pointer">About Tripwe</span>
          </a>
        </div>
      </div>
    </div>
    <nav class="bg-white w-full py-2">
      <div
        class="mx-auto max-w-screen-lg lg:max-w-screen-2xl xl:max-w-screen-xl flex justify-between items-center gap-8 text-gray-500 text-sm px-4 lg:px-0">
        <div class="flex w-full gap-4">
          <router-link :to="{ name: 'Home' }">
            <img src="@/assets/icons/logo-tripwe-new.svg" class="w-full h-full" />
          </router-link>
          <div class="w-full flex items-center">
            <div class="relative w-full">
              <div class="flex h-8 w-full items-center border-none rounded-lg overflow-hidden bg-f5">
                <button
                  class="relative h-full sm:w-11 w-7 flex-shrink-0 p-0 outline-none bg-transparent hover:bg-e2 transition-all duration-500">
                  <svg
                    class="absolute top-1 sm:right-3 right-0.5 w-6 h-6 text-black"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="-3 -3 30 30">
                    <path
                      d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
                  </svg>
                </button>
                <input
                  placeholder="Search trip packages"
                  maximum-scale="1"
                  class="sm:px-4 w-full bg-transparent outline-none cursor-pointer"
                  name="search"
                  autocomplete="off"
                  v-model="searchKeyword"
                  type="search"
                  @keyup.enter="handleSearch" />
              </div>
              <div
                v-if="menus.search"
                class="absolute sm:right-0 top-full z-20 sm:w-full w-80 mt-2 overflow-hidden border border-gray-300 bg-white rounded-md shadow-xl -ml-28 sm:ml-0">
                <div class="p-3">
                  <div class="flex flex-row justify-between">
                    <div class="font-bold text-black">Your History</div>
                    <font-awesome-icon
                      icon="fa-solid fa-xmark"
                      class="text-lg cursor-pointer"
                      size="lg"
                      @click="toggleSearchMenu(false)" />
                  </div>
                  <div class="flex flex-wrap">
                    <div
                      v-for="(word, idx) in historySearch"
                      :key="idx"
                      class="cursor-pointer rounded-2xl bg-blue-50 text-black text-sm p-2 m-1"
                      @click="selectSearch(word.words)">
                      {{ word.words }}
                    </div>
                  </div>
                  <div class="font-bold text-black">Search By Category</div>
                  <div class="flex flex-wrap">
                    <div
                      class="cursor-pointer rounded-2xl border border-1 text-black text-sm p-2 m-1 flex flex-row"
                      :class="{ 'bg-green-100': search.water }"
                      @click="search.water = !search.water">
                      <img class="object-fill h-6 w-6 mx-auto" src="@/assets/icons/icon-water-advanture.svg" />
                      Watersport
                    </div>
                    <div
                      class="cursor-pointer rounded-2xl border border-1 text-black text-sm p-2 m-1 flex flex-row"
                      :class="{ 'bg-green-100': search.land }"
                      @click="search.land = !search.land">
                      <img class="object-fill h-6 w-6 mx-auto" src="@/assets/icons/icon-land-advanture.svg" />
                      Land Adventure
                    </div>
                    <div
                      class="cursor-pointer rounded-2xl border border-1 text-black text-sm p-2 m-1 flex flex-row"
                      :class="{ 'bg-green-100': search.sky }"
                      @click="search.sky = !search.sky">
                      <img class="object-fill h-6 w-6 mx-auto" src="@/assets/icons/icon-sky-advanture.svg" />
                      Sky Adventure
                    </div>
                  </div>
                  <div class="font-bold text-black">Trending Search</div>
                  <div class="flex flex-wrap">
                    <div
                      v-for="(top, idxtop) in topSearch"
                      :key="idxtop"
                      class="cursor-pointer rounded-2xl bg-blue-50 text-black text-sm p-2 m-1"
                      @click="selectSearch(top.words)">
                      {{ top.words }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="hidden w-full lg:flex gap-8">
          <a class="flex items-center cursor-pointer" @click="redirectTripwepay">
            <img :src="icons.balance" />
            <div class="ml-2">
              <div class="text-gray-700 font-semibold">Balance</div>
              <div class="text-primary font-bold">
                {{ saldo.SaldoAktif | toCurrency }}
              </div>
            </div>
          </a>
          <a class="flex items-center cursor-pointer" @click="handleRedirectDana">
            <img :src="icons.dana" />
            <div class="ml-2">
              <div class="text-gray-700 font-semibold">Dana</div>
              <div class="text-primary font-bold" v-if="$store.state.connectDana">
                {{ $store.state.saldoDana.balance | toCurrency }}
              </div>
              <div class="text-primary font-bold" v-else>Connect to Dana</div>
            </div>
          </a>
          <router-link :to="{ name: 'AccountVoucher' }" class="flex items-center">
            <img :src="icons.voucher" />
            <div class="ml-2">
              <div class="text-gray-700 font-semibold">My Vouchers</div>
              <div class="text-primary font-bold">{{ voucherCount }} Vouchers</div>
            </div>
          </router-link>
          <!-- <router-link
            :to="{ name: 'EventTicket' }"
            class="flex items-center"
          >
            <img :src="icons.ticket" class="w-1/2 h-1/2" />
            <div class="ml-2">
              <div class="text-gray-700 font-semibold">My Ticket</div>
              <div class="text-primary font-bold">
                {{ ticketCount }} Events
              </div>
            </div>
          </router-link> -->
        </div>
        <div class="flex flex-shrink-0 items-center">
          <div
            v-if="isLoggedIn"
            @click="$router.push({ name: 'AccountProfile' })"
            class="hidden md:flex items-center cursor-pointer mr-2">
            <div class="font-semibold text-gray-800 text-base" v-if="user.nama.split(' ').length > 2">
              {{ user.nama.split(" ")[0] + " " + user.nama.split(" ")[1] }}
            </div>
            <div class="font-semibold text-gray-800 text-base" v-else>
              {{ user.nama }}
            </div>
            <img :src="user.foto" class="object-cover w-10 h-10 rounded-full border border-gray-300" />
          </div>
          <div v-else class="hidden lg:flex space-x-4 items-center mr-2">
            <button
              @click="redirect({ name: 'Login' })"
              class="text-white font-medium bg-primary hover:bg-primary-darker border-2 border-transparent py-1 px-6 rounded-lg">
              Login
            </button>
            <button
              @click="redirect({ name: 'Register' })"
              class="text-primary font-medium bg-white hover:bg-gray-100 border-2 border-primary py-1 px-4 rounded-lg">
              Sign Up
            </button>
          </div>
          <div class="flex space-x-6">
            <div class="space-x-6 flex">
              <div class="relative">
                <div @click="toggleNotificationMenu" class="cursor-pointer">
                  <font-awesome-icon v-if="menus.notification" icon="fa-solid fa-xmark" size="lg" />
                  <font-awesome-layers v-else class="fa-lg">
                    <font-awesome-icon icon="fa-solid fa-bell" :class="{ 'text-yellow': hasUnreadNotifications }" />
                    <font-awesome-icon
                      v-if="hasUnreadNotifications"
                      icon="fa-solid fa-circle"
                      transform="shrink-8 up-6 right-8"
                      class="text-red-600"
                      size="lg" />
                  </font-awesome-layers>
                </div>
                <div
                  v-if="menus.notification"
                  class="absolute right-0 z-20 w-72 sm:w-96 mt-2 overflow-hidden border border-gray-300 bg-white rounded-md shadow-xl">
                  <div class="font-bold text-gray-800 p-3 border-b shadow-md">Notification</div>
                  <div class="p-3">
                    <div v-for="(notification, index) in latestNotifications" :key="notification.id_notifikasi">
                      <div class="text-primary font-semibold truncate">
                        {{ notification.judul }}
                      </div>
                      <div class="text-xs text-gray-700 font-medium">
                        {{ notification.waktu_buat }}
                      </div>
                      <p class="text-xs text-gray-700 font-medium truncate">
                        {{ notification.isi }}
                      </p>
                      <hr v-if="index < latestNotifications.length - 1" class="my-2" />
                    </div>
                    <div class="mt-6 text-right">
                      <span
                        @click="redirect({ name: 'Notifications' })"
                        class="font-semibold text-primary hover:text-primary-darker cursor-pointer">
                        See more
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hidden sm:flex space-x-6">
                <font-awesome-icon
                  @click="redirect({ name: 'Wishlist' })"
                  icon="fa-solid fa-heart"
                  size="lg"
                  class="cursor-pointer" />
                <font-awesome-icon icon="fa-solid fa-envelope" size="lg" class="cursor-pointer" />
              </div>
            </div>
            <!-- <div class="relative">
              <div @click="toggleNotificationMenu" class="cursor-pointer">
                <font-awesome-icon
                  v-if="menus.notification"
                  icon="fa-solid fa-xmark"
                  size="lg"
                />
                <font-awesome-layers v-else class="fa-lg">
                  <font-awesome-icon
                    icon="fa-solid fa-bell"
                    :class="{ 'text-yellow': hasUnreadNotifications }"
                  />
                  <font-awesome-icon
                    v-if="hasUnreadNotifications"
                    icon="fa-solid fa-circle"
                    transform="shrink-8 up-6 right-8"
                    class="text-red-600"
                  />
                </font-awesome-layers>
              </div>
              <div
                v-if="menus.notification"
                class="absolute right-0 z-20 w-72 sm:w-96 mt-2 overflow-hidden border border-gray-300 bg-white rounded-md shadow-xl"
              >
                <div class="font-bold text-gray-800 p-3 border-b shadow-md">
                  Notification
                </div>
                <div class="p-3">
                  <div
                    v-for="(notification, index) in latestNotifications"
                    :key="notification.id_notifikasi"
                  >
                    <div class="text-primary font-semibold truncate">
                      {{ notification.judul }}
                    </div>
                    <div class="text-xs text-gray-700 font-medium">
                      {{ notification.waktu_buat }}
                    </div>
                    <p class="text-xs text-gray-700 font-medium truncate">
                      {{ notification.isi }}
                    </p>
                    <hr
                      v-if="index < latestNotifications.length - 1"
                      class="my-2"
                    />
                  </div>
                  <div class="mt-6 text-right">
                    <span
                      @click="redirect({ name: 'Notifications' })"
                      class="font-semibold text-primary hover:text-primary-darker cursor-pointer"
                    >
                      See more
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <font-awesome-icon
              @click="redirect({ name: 'Wishlist' })"
              icon="fa-solid fa-heart"
              size="lg"
              class="cursor-pointer"
            />
            <font-awesome-icon
              icon="fa-solid fa-envelope"
              size="lg"
              class="cursor-pointer"
            /> -->
            <font-awesome-icon
              v-if="isLoggedIn"
              @click="logout"
              icon="fa-solid fa-arrow-right-from-bracket"
              size="lg"
              class="hidden lg:flex cursor-pointer" />
            <div class="relative lg:hidden">
              <div @click="toggleAccountMenu" class="cursor-pointer">
                <font-awesome-icon v-if="menus.account" icon="fa-solid fa-xmark" size="lg" />
                <font-awesome-icon v-else icon="fa-solid fa-bars" size="lg" />
              </div>
              <div
                v-if="menus.account"
                class="absolute right-0 z-20 w-64 p-2 mt-2 overflow-hidden border border-gray-300 bg-white rounded-md shadow-xl">
                <template v-if="isLoggedIn">
                  <div class="flex flex-row justify-between">
                    <div class="flex items-end cursor-pointer" @click="redirect({ name: 'AccountProfile' })">
                      <img :src="user.foto" class="object-cover w-12 h-12 rounded-full border border-gray-300 mr-2" />
                      <div class="font-semibold">
                        <div class="text-base text-gray-800" v-if="user.nama.split(' ').length > 2">
                          {{ user.nama.split(" ")[0] + " " + user.nama.split(" ")[1] }}
                        </div>
                        <div class="text-base text-gray-800" v-else>
                          {{ user.nama }}
                        </div>
                        <div class="text-primary">{{ user.telepon }}</div>
                      </div>
                    </div>
                  </div>
                  <hr class="border-gray-300 my-2" />
                </template>
                <div class="flex items-start flex-col w-full justify-start space-y-2 px-2">
                  <div class="flex items-center">
                    <router-link :to="{ name: 'Topup' }" class="flex items-center">
                      <img :src="icons.balance" />
                      <div class="ml-2 text-xs">
                        <div class="text-gray-700 font-semibold">Balance</div>
                        <div class="text-primary font-bold">
                          {{ saldo.SaldoAktif | toCurrency }}
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <div class="flex items-center">
                    <router-link :to="{ name: 'Dana' }" class="flex items-center">
                      <img :src="icons.dana" />
                      <div class="ml-2 text-xs">
                        <div class="text-gray-700 font-semibold">Dana</div>
                        <div class="text-primary font-bold" v-if="$store.state.connectDana">
                          {{ $store.state.saldoDana.balance | toCurrency }}
                        </div>
                        <div class="text-primary font-bold" v-else>Connect to Dana</div>
                      </div>
                    </router-link>
                  </div>
                  <div class="flex items-center">
                    <router-link :to="{ name: 'AccountVoucher' }" class="flex items-center">
                      <img :src="icons.voucher" class="w-1/4 h-1/4" />
                      <div class="ml-2 text-xs">
                        <div class="text-gray-700 font-semibold">My Vouchers</div>
                        <div class="text-primary font-bold">{{ voucherCount }} Vouchers</div>
                      </div>
                    </router-link>
                  </div>
                  <!-- <div class="flex items-center">
                    <router-link
                      :to="{ name: 'EventTicket' }"
                      class="flex items-center"
                    >
                      <img :src="icons.ticket" class="w-1/4 h-1/4" />
                      <div class="ml-2 text-xs">
                        <div class="text-gray-700 font-semibold">My Ticket</div>
                        <div class="text-primary font-bold">
                          {{ ticketCount }} Events
                        </div>
                      </div>
                    </router-link>
                  </div> -->
                </div>
                <!-- Add Icon -->
                <div class="flex flex-col gap-4 items-start justify-between px-2 my-4 sm:hidden">
                  <div class="flex flex-row gap-3 items-center" @click="redirect({ name: 'Wishlist' })">
                    <font-awesome-icon icon="fa-solid fa-heart" size="lg" class="cursor-pointer" />
                    <p class="font-semibold">Wishlist</p>
                  </div>
                  <div class="flex flex-row gap-3 items-center">
                    <font-awesome-icon icon="fa-solid fa-envelope" size="lg" class="cursor-pointer" />
                    <p class="font-semibold">Message</p>
                  </div>
                  <a class="flex flex-row gap-3 items-center" @click="$router.push({ name: 'FAQ' })">
                    <font-awesome-icon icon="fa-regular fa-circle-question" size="lg" class="cursor-pointer" />
                    <p class="font-semibold">FAQ</p>
                  </a>
                  <a class="flex flex-row gap-3 items-center" @click="$router.push({ name: 'OurPartner' })">
                    <font-awesome-icon icon="fa-solid fa-people-group" class="cursor-pointer" />
                    <p class="font-semibold">Our Partner</p>
                  </a>
                  <a class="flex flex-row gap-3 items-center" @click="$router.push({ name: 'About' })">
                    <font-awesome-icon icon="fa-solid fa-circle-info" class="cursor-pointer" />
                    <p class="font-semibold">About Tripwe</p>
                  </a>
                  <a class="flex flex-row gap-3 items-center" href="https://wa.me/message/LAFEDFCR4JY4L1">
                    <font-awesome-icon icon="fa-brands fa-whatsapp" size="lg" class="cursor-pointer" />
                    <p class="font-semibold">Customer Support</p>
                  </a>
                  <button class="flex items-center hover:text-secondary" type="button" @click="openSelectRegion" v-show="false">
                    <span class="hover:text-secondary cursor-pointer">
                      <img :src="require(`@/assets/icons/flags/${currentCountryCode}`)" width="20px" />
                    </span>
                    &nbsp; ({{ $store.state.countryCode }})
                  </button>
                </div>
                <!-- Add Icon -->
                <div class="mt-4 flex justify-center items-center">
                  <button
                    v-if="isLoggedIn"
                    @click="logout"
                    class="text-primary font-medium bg-white hover:bg-gray-100 border-2 border-primary py-1 px-4 rounded-lg">
                    Logout
                  </button>
                  <template v-else>
                    <button
                      @click="redirect({ name: 'Login' })"
                      class="text-white font-medium bg-primary hover:bg-primary-darker border-2 border-transparent py-1 px-6 rounded-lg">
                      Login
                    </button>
                    <button
                      @click="redirect({ name: 'Register' })"
                      class="text-primary font-medium bg-white hover:bg-gray-100 border-2 border-primary py-1 px-4 rounded-lg ml-2">
                      Sign Up
                    </button>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <confirmation-dialog ref="confirmationDialog"></confirmation-dialog>
    <modal-region ref="modalRegionRef"></modal-region>
    <modal-create-pin ref="modalPin" :newPin="true"></modal-create-pin>
    <modal-open-link ref="modalOpenLink"></modal-open-link>
  </header>
</template>

<script>
import ModalRegion from "@/components/layout/ModalRegion.vue";

export default {
  name: "Header",
  components: {
    ConfirmationDialog: () => import("@/components/layout/ConfirmationDialog.vue"),
    ModalCreatePin: () => import("@/components/layout/ModalCreatePin.vue"),
    ModalOpenLink: () => import("@/components/layout/ModalOpenLink.vue"),
    ModalRegion,
  },
  props: {
    hideSearch: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      icons: {
        balance: require("@/assets/logo-balance.png"),
        tripwePay: require("@/assets/icons/ic-pay.svg"),
        voucher: require("@/assets/icons/ic-voucher.svg"),
        dana: require("@/assets/logo-dana.png"),
        ticket: require("@/assets/icons/ic-ticket.svg"),
      },
      menus: {
        account: false,
        notification: false,
        search: false,
      },
      search: {
        water: false,
        land: false,
        sky: false,
      },
      searchKeyword: "",
      historySearch: [],
      topSearch: [],
    };
  },
  mounted() {
    if (this.$store.state.countryCode == "") {
      // this.$refs.modalRegionRef.open();
    }
  },
  created() {
    if (this.$route.query.keyword != null) {
      this.searchKeyword = this.$route.query.keyword;
    }
  },
  computed: {
    currentCountryCode() {
      if (this.$store.state.countryCode == "MY") {
        return "malaysia.svg";
      } else {
        return "indonesia.svg";
      }
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    user() {
      return this.$store.state.user;
    },
    saldo() {
      return this.$store.state.saldo;
    },
    voucherCount() {
      return this.$store.state.voucherCount;
    },
    ticketCount() {
      // return this.$store.state.voucherCount;
      return 0;
    },
    latestNotifications() {
      return this.$store.getters.latestNotifications;
    },
    hasUnreadNotifications() {
      return this.$store.getters.hasUnreadNotifications;
    },
  },

  methods: {
    openSelectRegion() {
      return;
      // this.$refs.modalRegionRef.open();
    },
    async handleRedirectDana() {
      if (this.$store.state.connectDana) {
        return this.$router.push({ name: "Dana" });
      }
      this.$store.commit("setOverlayLoading", true);
      try {
        const response = await this.$http.get(`${this.$apiTripweWeb}/dana/accounts/oauth`);
        if (response.data.status != 200) {
          throw response.data.message;
        }
        this.$refs.modalOpenLink.open({ link: response.data.data.url, text: "Click to open Dana page" });
      } catch (error) {
        if(error.response.status == 401){
          this.$toasted.global.error(error.response.data.message);
        }else{
          this.$toasted.global.error(error);
        }
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    redirectTripwepay() {
      if (this.$store.state.user.status_pin == 0) {
        this.$refs.modalPin.open({
          button: "Save",
        });
        return;
      }

      this.$router.push({ name: "TripwePay" });
      return;
    },
    toggleAccountMenu() {
      this.menus.notification = false;
      this.menus.search = false;
      this.menus.account = !this.menus.account;
    },
    toggleNotificationMenu() {
      this.menus.account = false;
      this.menus.search = false;
      this.menus.notification = !this.menus.notification;
      this.loadNofitication();
      this.readNotification();
    },
    toggleSearchMenu(open) {
      this.menus.account = false;
      this.menus.notification = false;
      this.menus.search = open;
    },
    handleSearch() {
      try {
        this.$router.push({
          name: "ListPaket",
          params: {
            type: "safari",
            category: "all",
          },
          query: {
            keyword: this.searchKeyword,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    async loadNofitication() {
      try {
        const response = await this.$http.get(`${this.$apiTripweWeb}/notification`);
        if (response.data.status == 20000) {
          this.$store.commit("setNotifications", response.data.data);
        }
      } catch (error) {
        this.$toasted.global.error(error);
      }
    },
    async readNotification() {
      try {
        const response = await this.$http.post(`${this.$apiTripweWeb}/notification/update`);
        if (response.data.status != 20000) {
          throw response.data.message;
        }
        this.loadNofitication();
      } catch (error) {
        this.$toasted.global.error(error);
      }
    },
    async selectSearch(str) {
      this.searchKeyword = str;
      this.searching();
    },
    async searching() {
    },
    async logout() {
      const confirmed = await this.$refs.confirmationDialog.open({
        title: "Logout",
        message: "Are you sure want to logout ?",
        button: "OK",
      });

      if (confirmed) {
        try {
          this.$store.dispatch("logout");
          this.$router.push({ name: "Home" });
          this.menus.account = false;
        } catch (error) {
          this.$toasted.global.error(error);
        }
      }
    },
    async redirect(con) {
      this.$router.push(con);
      this.menus.account = false;
      this.menus.notification = false;
      this.menus.search = false;
    },
  },
};
</script>
