<template>
  <footer class="text-white bg-black2 pt-4">
    <div
      class="px-4 py-4 mx-auto max-w-screen-lg lg:max-w-screen-2xl xl:max-w-screen-xl flex justify-between items-center"
    >
      <div
        class="grid grid-cols-1 md:grid-rows-1 md:grid-cols-4 xl:grid-cols-4 gap-8 md:justify-between item-center w-full"
      >
        <div>
          <span class="md:mb-6 text-sm font-semibold uppercase"
            >Contact Info</span
          >
          <ul class="text-sm flex flex-col gap-4 mt-4">
            <li>
              <ul class="flex flex-col gap-2">
                <li>
                  <font-awesome-icon icon="fa-solid fa-phone" class="mr-1" />
                  +62 838-7056-2838
                </li>
                <li>
                  <font-awesome-icon icon="fa-solid fa-envelope" class="mr-1" />
                  info@tripwe.com
                </li>
              </ul>
            </li>

            <li>
              PT. Tripedia Global Adventura<br />
              Jakarta<br />
              <!-- Dermaga 17 Marina Ancol, Kec. Pademangan, Jakarta Utara - DKI Jakarta 14430 -->
              Jl. Sultan Iskandar Muda No.27, Kebayoran Lama, Jakarta Selatan -
              12240
            </li>
          </ul>
        </div>
        <div>
          <span class="md:mb-6 text-sm font-semibold uppercase">About Us</span>
          <ul class="flex flex-col gap-2 mt-4">
            <li>
              <!-- <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://tripwe.com/karir/"
                                class="flex items-center hover:underline"
                            >Career</a> -->
              <a
                target="_blank"
                rel="noopener noreferrer"
                @click="$router.push({ name: 'Career' })"
                class="flex items-center hover:underline cursor-pointer"
                >Career</a
              >
            </li>
            <li>
              <!-- <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://tripwe.com/f-a-q/"
                                class="flex items-center hover:underline"
                            >FAQ</a> -->
              <a
                target="_blank"
                rel="noopener noreferrer"
                @click="$router.push({ name: 'FAQ' })"
                class="flex items-center hover:underline cursor-pointer"
                >FAQ</a
              >
            </li>
            <li>
              <!-- <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://tripwe.com/tentang-kami-2/"
                                class="flex items-center hover:underline"
                            >Our Story</a> -->
              <a
                target="_blank"
                rel="noopener noreferrer"
                @click="$router.push({ name: 'About' })"
                class="flex items-center hover:underline cursor-pointer"
                >About Tripwe</a
              >
            </li>
            <li>
              <a
                  target="_blank"
                  rel="noopener noreferrer"
                  @click="$router.push({ name: 'PressRoom' })"
                  class="flex items-center hover:underline cursor-pointer"
              >Press Room</a>
            </li>
          </ul>
        </div>
        <div>
          <span class="md:mb-6 text-sm font-semibold uppercase">Resource</span>
          <ul class="flex flex-col gap-2 mt-4">
            <li class="">
              <a
                @click="$router.push({ name: 'Blog' })"
                target="_blank"
                class="hover:underline cursor-pointer"
                >Blog</a
              >
            </li>
            <li class="">
              <!-- <a href="https://tripwe.com/kontak/" target="_blank" class="hover:underline cursor-pointer">Customer Support</a> -->
              <a
                target="_blank"
                class="hover:underline cursor-pointer"
                @click="$router.push({ name: 'CustomerSupport' })"
                >Customer Support</a
              >
            </li>
            <li class="">
              <!-- <a href="https://tripwe.com/our-branch/" target="_blank" class="hover:underline cursor-pointer">Our Branch</a> -->
              <a
                @click="$router.push({ name: 'OurBranch' })"
                target="_blank"
                class="hover:underline cursor-pointer"
                >Our Branch</a
              >
            </li>
            <li class="">
              <!-- <a href="https://tripwe.com/privacy-policy/" target="_blank" class="hover:underline cursor-pointer">Privacy Policy</a> -->
              <a
                @click="$router.push({ name: 'PrivacyPolicy' })"
                target="_blank"
                class="hover:underline cursor-pointer"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a
                @click="$router.push({ name: 'UserAgreement' })"
                target="_blank"
                class="hover:underline cursor-pointer"
                >User Agreement</a
              >
            </li>
          </ul>
        </div>
        <div class="">
          <span class="md:mb-6 text-sm font-semibold uppercase">Follow Us</span>
          <div class="flex flex-row space-x-6 space-y-0 mt-4">
            <a
              href="https://www.facebook.com/tripweindonesia/"
              target="_blank"
              class="hover:text-gray-400"
            >
              <font-awesome-icon icon="fa-brands fa-facebook" class="h-7" />
            </a>
            <a
              href="https://www.instagram.com/tripweofficial/"
              target="_blank"
              class="hover:text-gray-400 block"
            >
              <font-awesome-icon icon="fa-brands fa-instagram" class="h-7" />
            </a>
            <a
              href="https://www.youtube.com/c/tripweindonesia"
              target="_blank"
              class="hover:text-gray-400 block"
            >
              <font-awesome-icon icon="fa-brands fa-youtube" class="h-7" />
            </a>
            <a
              href="https://www.tiktok.com/@tripwe"
              target="_blank"
              class="hover:text-gray-400 block"
            >
              <font-awesome-icon icon="fa-brands fa-tiktok" class="h-7" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="sm:flex sm:items-center justify-center p-4 bg-black">
      <span class="text-sm text-gray-400 text-center"
        >Copyright © 2022<a
          href="https://tripwe.com"
          target="_blank"
          class="hover:underline cursor-pointer ml-2"
          >PT. TRIPEDIA GLOBAL ADVENTURA | Tripwe</a
        >.
      </span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.cls-1 {
  fill: #fff;
}

.tw-logo {
  height: 60px;
}

a {
  color: #ffffff;
}
</style>
